import React from "react";
import Logo from "../images/logo.jpg";
import styles from "./Temp.module.css"; // Import the CSS module

const Temp = () => {
  const handleInstagram = () => {
    window.open("https://instagram.com/fullswingsound", "_blank");
  };

  const handleReviews = () => {
    window.open(
      "https://www.weddingwire.com/reviews/full-swing-sound-buffalo/f02e086d7f412111.html",
      "_blank"
    );
  };

  return (
    <div className={styles.container}>
      <img src={Logo} alt="Logo" className={styles.logo} />
      <p className={styles.text}>Pardon us while we are remodelling!</p>
      <p className={styles.text}>
        Looking to get in contact in the meantime? Email us at{" "}
        <strong>booking@fullswingsound.com</strong>
      </p>
      <div className={styles.buttonContainer}>
        <button onClick={handleInstagram}>Follow on Instagram</button>
        <button onClick={handleReviews}>Look At Our Reviews</button>
      </div>
    </div>
  );
};

export default Temp;
