import Temp from "./components/Temp";

function App() {
  return (
    <div className="App">
      <Temp />
    </div>
  );
}

export default App;
